import { getLang } from './storage'

const gameList = [
    {
        name: 'xy',
        loginType: 'iframe',        // 登录方式（可以不填， url | iframe，默认 iframe）
        icon: require('images/game/xy-icon.png'),
        regions: [
            {
                regionName: 'gat',      // == 港澳台 ==
                regionKey: ['.gamenuts.com.tw'],     // 域名匹配关键词（可以填多个匹配规则）
                loginKey: 'XY_TW_SG',               // 登录key传参（登录接口传参）
                gameCode: 1675759979322,

                title: 'xy.game.titleGat',          // 标题，可以自定义
                platLogo: require('images/logo/wjgy.png'),
                platLink: 'https://www.gamenuts.com.tw'
            },
            {
                regionName: 'dny',      // == 东南亚 ==
                regionKey: ['.gaming.com'],
                loginKey: 'XY_SEA',
                gameCode: process.env.VUE_APP_DNY_GAMECODE,

                title: 'xy.game.titleDny'
            }
        ]
    },
    {
        name: 'ps',
        icon: require('images/game/ps-icon.png'),
        regions: [
            {
                regionName: 'jp',         // 日本
                regionKey: ['.haikyu-flyhigh.jp'],
                loginKey: 'HAIKYU_JP',
                gameCode: 1703733676736,
            }
        ]
    }
]

const gameLogoList = {
    xy: {
        default: 'en-us',
        list: [
            {
                lang: 'en-us',
                logo: 'xy-en.png',
                logoWhite: 'xy-en-white.png',
                link: ''
            },
            {
                lang: 'th-th',
                logo: 'xy-th.png',
                logoWhite: 'xy-th-white.png',
                link: ''
            },
            {
                lang: 'id-id',
                logo: 'xy-id.png',
                logoWhite: 'xy-id-white.png',
                link: ''
            },
            {
                lang: 'vi-vn',
                logo: 'xy-vi.png',
                logoWhite: 'xy-vi-white.png',
                link: ''
            }
        ]
    },
    ps: {
        default: 'ja-jp',
        list: [
            {
                lang: 'ja-jp',
                logo: 'ps-jp.png',
                link: ''
            }
        ]
    }
}

// 通过path查询游戏name
const getGameName = () => {
    if (location.href.indexOf('/xy/') > -1) {
        return 'xy'
    }else if (location.href.indexOf('/ps/') > -1) {
        return 'ps'
    } else {
        return ''
    }
}

// 通过域名查询地域信息
const getRegionInfoByUrl = () => {
    let arr = []
    gameList.map(game => {
        const regions = game.regions.map(info => {
            return { ...info, gameType: game.name }
        })
        arr.push(...regions)
    })
    const regionInfo = arr.find(item => {
        let count = 0
        item.regionKey?.map(val => {
            if(location.host.indexOf(val) > -1) {
                count ++
            }
        })
        return item.regionKey?.length === count
    })
    return regionInfo || {}
}

// 通过path查询游戏信息（注：这里为了能在相同域名下访问不通游戏，该方法不能在根路由重定向前使用）
export const getGameInfoByUrl = () => {
    const name = getGameName()
    if (!name) {
        throw new Error('getGameNameByUrl：No Game')
    }
    const gameInfo = gameList.find(item => {
        return item.name === name
    }) || {}
    const { regions, ...info } = gameInfo
    return { ...info, ...getRegionInfoByUrl() }       // 这里整合 游戏、地域 信息
}

export const getGameTypeByUrl = () => getRegionInfoByUrl().gameType

export const getGameNameByUrl = () => getGameInfoByUrl().name
export const getGameTitleByUrl = () => getGameInfoByUrl().title
export const getGameIconByUrl = () => getGameInfoByUrl().icon
export const getGamePlatLinkByUrl = () => getGameInfoByUrl().platLink

export const getGamePlatLogoByUrl = (type) => getGameInfoByUrl().platLogo || (type === 'black' ? require('images/logo/cyou-black.png') : require('images/logo/cyou.png'))

const getGameLogoInfoByUrl = (lang) => {
    lang = lang || getLang()
    lang = lang?.toLocaleLowerCase()

    const gameName = getGameName()
    const logoLangs = gameLogoList[gameName]?.list || []
    const logoInfoDefault = logoLangs.find(item => item.lang === gameLogoList[gameName]['default'])
    const logoInfo = logoLangs.find(item => item.lang === lang) || logoInfoDefault

    return logoInfo
}
export const getGameLogoByUrl = (lang, color) => {
    const info = getGameLogoInfoByUrl(lang)
    if(!info) {
        console.error('没有找到logo信息')
        return
    }
    const name = color === 'white' ? (info.logoWhite || info.logo) : info.logo

    return require(`images/logo/${name}`)
}

export const getGameLinkByUrl = (lang) => {
    
    return ''
}

export const isCyGat = () => getGameInfoByUrl().regionName === 'gat'
export const isCyDny = () => getGameInfoByUrl().regionName === 'dny'