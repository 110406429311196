import Vue from 'vue'
import VueRouter from 'vue-router'
import { getGameTypeByUrl } from '@/utils/game'

Vue.use(VueRouter)

export default new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/index.html',
            redirect: `/${getGameTypeByUrl()}`,
        },
        {
            path: '/',
            redirect: `/${getGameTypeByUrl()}`,
        },
        {
            path: '/xy/',
            name: 'xy',
            redirect: '/xy/index',
            component: () => import('@/pages/xy/layout'),
            children: [
                {
                    path: 'index',
                    name: 'xyIndex',
                    component: () => import('@/pages/xy/index')
                },
                {
                    path: 'record',
                    name: 'xyRecord',
                    component: () => import('@/pages/xy/record')
                },
                {
                    path: 'payResult',
                    name: 'payResult',
                    component: () => import('@/pages/xy/payResult')
                }
            ]
        },
        {
            path: '/ps/',
            name: 'ps',
            redirect: '/ps/index',
            component: () => import('@/pages/ps/layout'),
            children: [
                {
                    path: 'index',
                    name: 'psIndex',
                    component: () => import('@/pages/ps/index')
                },
                {
                    path: 'record',
                    name: 'psRecord',
                    component: () => import('@/pages/ps/record')
                },
                {
                    path: 'payResult',
                    name: 'psResult',
                    component: () => import('@/pages/ps/payResult')
                }
            ]
        }
    ]
})

