// import 'babel-polyfill'
import { Loading } from 'element-ui';
import Es6Promise from 'es6-promise';
// import 'promise-polyfill/src/polyfill'
import Vue from 'vue';
import VueClipboard from 'vue-clipboard2';
import aihelper from './aihelper';
import App from './App.vue';
import { formatCurrencyToThousand } from './filter';
import i18n from './i18n';
import router from './router';
import store from './store';
import { isMock } from './utils/constants';
import { logSignUp } from './utils/log';

Vue.use(VueClipboard)

Vue.use(Loading.directive)
Vue.filter(formatCurrencyToThousand.name,formatCurrencyToThousand)

console.log('NODE_ENV：', process.env.NODE_ENV)
console.log('VUE_APP_ENV', process.env.VUE_APP_ENV)

isMock && require('./mock')

Es6Promise.polyfill()

window.T = key => i18n.t(key)
Vue.prototype.$sys = (key, params) => i18n.t(`sys.${key}`, params)
Vue.prototype.$xy = (key, params) => i18n.t(`xy.${key}`, params)
Vue.prototype.$ps = (key, params) => i18n.t(`ps.${key}`, params)

Vue.prototype.destroyAiHelper = aihelper({
  containerId:'',
  aiStyle:'/static/css/aihelp.css'
}, (helper) => {
  if(!helper.config.language){
    Vue.prototype.$aihelper = helper
    helper.updateSDKLanguage(store.getters.lang)
    store.subscribe((mutation, state) => {
      if(mutation.type.indexOf('SET_LANG') > -1){
        helper.updateSDKLanguage(mutation.payload)
      }
    })
  }

})

window.L = key => i18n.t(key)         // 旧
Vue.prototype.L = key => i18n.t(key)  // 旧

Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  i18n,
  store,
  render: (h) => h(App)
})


// 监听iframe传参
window.addEventListener('message', (e) => {
  const method = e.data?.loginMethodChosen
  method && logSignUp({ method })
})

document.getElementById('initLoader').remove()


const prettyPrint = (title, text, color) => {
  console.log(
    `%c ${title} %c ${text} %c`,
    `background:${color};border:1px solid ${color}; padding: 1px; border-radius: 2px 0 0 2px; color: #fff;`,
    `border:1px solid ${color}; padding: 1px; border-radius: 0 2px 2px 0; color: ${color};`,
    'background:transparent'
  );
}
prettyPrint(process.env.BRANCH,process.env.COMMITHASH,'#67C23A')
