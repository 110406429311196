import { apiLogin, apiLogout, apiLogoutUrl, apiUserInfo } from '@/api/user';
import i18n from '@/i18n';
import { isMobile, tipsError } from '@/utils';
import { isChrome, isIos } from '@/utils/constants';
import { getGameInfoByUrl } from '@/utils/game';
import { logClickSave, logLogin, logLoginFail, logPopupClick, logPopupDisplay, logSignUp } from '@/utils/log';
import {
    getRedirectUrl,
    getUserInfo,
    removeSelectedRole,
    removeUserInfo,
    setRedirectUrl,
    setUserInfo,
} from '@/utils/storage';

const state = {
    isPopLogin: false,
    isPopHelp: false,
    isPopAccount: false,
    isPopSaveDesktop: false,
    clientWidth: null,

    deferredPrompt: null, // 保存到桌面
    isInitFinish: false,
    userInfo: getUserInfo() || {}
}

const mutations = {
    SET_IS_POP_LOGIN: (state, val) => {
        state.isPopLogin = val
    },
    SET_IS_POP_HELP: (state, val) => {
        state.isPopHelp = val
    },
    SET_IS_POP_SAVE_DESKTOP: (state, val) => {
        state.isPopSaveDesktop = val
    },
    SET_IS_POP_ACCOUNT: (state, val) => {
        state.isPopAccount = val
    },
    SET_CLIENT_WIDTH: (state, val) => {
        state.clientWidth = val
    },

    SET_DEFERRED_PROMPT: (state, val) => {
        state.deferredPrompt = val
    },
    SET_IS_INIT_FINISH: (state, val) => {
        state.isInitFinish = val
    },
    SET_USER_INFO: (state, info) => {
        const userInfo = { ...state.userInfo, ...info }
        setUserInfo(userInfo)
        state.userInfo = userInfo
    }
}

const actions = {
    login({ commit }, placement) {
        setRedirectUrl()

        const gameInfo = getGameInfoByUrl()

        if(gameInfo.loginType === 'url') {
            location.href = this.loginUrl
        }else {
            commit('SET_IS_POP_LOGIN', true)
        }

        if(placement) logSignUp({ placement })
    },
    async loginByTicket({ commit }, ticket) {
        if (ticket.indexOf('login_error_not_activated') > -1) {
            tipsError(i18n.t('sys.warn.loginErrorVerify'))

            logLoginFail(ticket)
            return
        }

        await apiLogin({
            ticket,
            redirectUrl: getRedirectUrl()
        }).then(result => {
            if (result.code != 0) {
                
                logLoginFail(result.message)

                if(result.code === 12001) {
                    tipsError(i18n.t('sys.warn.authVerifyFail'))
                }else {
                    tipsError(result.message)

                    if(result.code === 13001) location.reload()
                }
            }
        })

    },
    async getUserInfo({ state, commit }) {
        try {
            const result = await apiUserInfo()

            commit('SET_IS_INIT_FINISH', true)      // 完成初始化

            if (result.code === 0 && result.data && Object.keys(result.data).length > 0) {
                const { userName, userCode, userSource, gameRoleBound } = result.data
                const userInfo = { ...gameRoleBound, userCode, userSource, userName }

                if(gameRoleBound && !state.userInfo.isLogFirstRole) {
                    logLogin(gameRoleBound.gameRoleCode)
                    userInfo.isLogFirstRole = 1
                }

                commit('SET_USER_INFO', userInfo)
            }else {
                commit('SET_USER_INFO', {})
                removeUserInfo()
            }
        }catch(e) {
            commit('SET_IS_INIT_FINISH', true)      // 完成初始化
        }
    },
    async logout() {
        const result = await apiLogout()
        if (result.code === 0) {
            removeUserInfo()
            removeSelectedRole()
            window.location.href = apiLogoutUrl()
        }
    },
    saveDesktop({ state, commit }){
        logClickSave()
        if(isMobile()) {
            // 当前浏览器为移动端
            if (isIos) {
                // 打开iOS设备添加到桌面引导弹窗
                commit('SET_IS_POP_SAVE_DESKTOP', true);
            } else {
                // 当前浏览器不是iOS设备
                // 打开通用弹窗提示
                commit('SET_IS_POP_SAVE_DESKTOP', true);
            }
            logPopupDisplay('popup_mb');
        } else {
            // 当前浏览器为PC端
            if (isChrome) {
                // 打开PWA下载弹窗
                if (state.deferredPrompt) {
                  state.deferredPrompt.prompt();
                  logPopupDisplay('popup_pc1');
                  state.deferredPrompt.userChoice.then(function (result) {
                      if (result.outcome === 'accepted') {
                          // 同意安装应用埋点
                          let popupClick = {
                              popup_type: 'popup_pc1',
                              click_result: 'install'
                          }
                          logPopupClick(popupClick);
                          commit('SET_DEFERRED_PROMPT', null);
                      } else {
                          // 不同意安装应用埋点
                          let popupClick = {
                              popup_type: 'popup_pc1',
                              click_result: 'cancel'
                          }
                          logPopupClick(popupClick);
                      }
                  });
                }
            } else {
                // 当前浏览器不是Chrome内核
                // 打开弹窗提示
                commit('SET_IS_POP_SAVE_DESKTOP', true);
                logPopupDisplay('popup_pc2');
            }
        }
    }
}

export default {
    namespaced: true,    // 命名空间，设置被 mapXxx 方法识别到
    state,
    mutations,
    actions
}
