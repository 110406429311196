import instance from '@/utils/request'

export const apiCommodityList = (params) => {
    return instance.get('/app/commodity/list', {
        params,
        customConfig: {
            disableAuth: true
        }
    })
}

export const apiCreateOrder = (params) => {
    return instance({
        url: '/app/order/createOrderThenPay',
        // method: 'POST',
        params,
        customConfig: {
            disableTips: true
        }
    })
}

export const apiOrderResult = (orderCode) => {
    return instance.get('/app/order/getPayResult', {
        params: {
            orderCode
        }
    })
}

export const apiOrderList = (params) => {
    return instance.get('/app/order/page', {
        params
    })
}

export const apiOrderCancel = (orderCode) => {
    return instance.get('/app/order/cancel', {
        params: {
            orderCode
        }
    })
}

