import Mock from 'mockjs'

Mock.mock(
    RegExp('.*/app/commodity/list.*'),
    {
        "code": 0,
        "message": "Operation successful",
        "data": [
            {
                "gameCode": "1703733676736",
                "commodityCode": "5199",
                "commodityName": "有償ダイヤ×160-英语",
                "commodityIcon": "https://xysy-cdn.gamenuts.com.tw/img/commodity/20241028/5dcf1324-d0e0-4c49-8612-146401c277f5.png",
                "commodityOriginalPrice": 19000,
                "commoditySellPrice": 19000,
                "commodityCurrency": "IDR",
                "commodityCurrencySymbol": "Rp",
                "currencyFrontLocation": "10",
                "preferentialExplain": "無償ダイヤ16 おまけ-英语",
                "payTypes": [
                    {
                        "payType": "ALIPAY_GLOBAL",
                        "payTypeName": "ALIPAY_GLOBAL",
                        "payLevel": 1,
                        "needValid": true,
                        "paySubTypes": [
                            {
                                "payType": "DANA",
                                "payTypeName": "DANA",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 300,
                                "amountLimitEnd": 10000000
                            },
                            {
                                "payType": "ONLINEBANKING_BNI",
                                "payTypeName": "BNI",
                                "category": "ONLINEBANKING",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 10000
                            },
                            {
                                "payType": "ONLINEBANKING_BCA",
                                "payTypeName": "BCA",
                                "category": "ONLINEBANKING",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 10000,
                                "amountLimitEnd": 100000000
                            },
                            {
                                "payType": "QRIS",
                                "payTypeName": "QRIS",
                                "category": "BANKTRANSFER",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 300,
                                "amountLimitEnd": 20000000
                            },
                            {
                                "payType": "BANKTRANSFER_BSI",
                                "payTypeName": "BSI",
                                "category": "BANKTRANSFER",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 1,
                                "amountLimitEnd": 20000
                            },
                            {
                                "payType": "CIMBNIAGA",
                                "payTypeName": "CIMB Niaga VA",
                                "category": "BANKTRANSFER",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 10000,
                                "amountLimitEnd": 100000000
                            },
                            {
                                "payType": "BANKTRANSFER_MAYBANK",
                                "payTypeName": "Maybank",
                                "category": "BANKTRANSFER",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 10000
                            },
                            {
                                "payType": "BANKTRANSFER_BNI",
                                "payTypeName": "BNI",
                                "category": "BANKTRANSFER",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 10000,
                                "amountLimitEnd": 100000000
                            },
                            {
                                "payType": "BANKTRANSFER_PERMATA",
                                "payTypeName": "Permata",
                                "category": "BANKTRANSFER",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 10000,
                                "amountLimitEnd": 100000000
                            },
                            {
                                "payType": "ATMTRANSFER_ID",
                                "payTypeName": "ATM Bersama/Prima/Alto",
                                "category": "BANKTRANSFER",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 10000,
                                "amountLimitEnd": 100000000
                            },
                            {
                                "payType": "SHOPEEPAY_ID",
                                "payTypeName": "ShopeePay",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 1,
                                "amountLimitEnd": 10000000
                            },
                            {
                                "payType": "ALIPAY_CN",
                                "payTypeName": "AlipayCN",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 216.34
                            }
                        ]
                    }
                ],
                "cornerMarkText": "首充双倍666",
                "isRecommend": true
            },
            {
                "gameCode": "1703733676736",
                "commodityCode": "5200",
                "commodityName": "有償ダイヤ×650-英语",
                "commodityIcon": "https://xysy-cdn.gamenuts.com.tw/img/commodity/20241025/36b4e65a-082a-44dc-a758-f5c4c5fc1ee5.png",
                "commodityOriginalPrice": 69000,
                "commoditySellPrice": 69000,
                "commodityCurrency": "IDR",
                "commodityCurrencySymbol": "Rp",
                "currencyFrontLocation": "10",
                "preferentialExplain": "無償ダイヤ125 おまけ-英语",
                "payTypes": [
                    {
                        "payType": "ALIPAY_GLOBAL",
                        "payTypeName": "ALIPAY_GLOBAL",
                        "payLevel": 1,
                        "needValid": true,
                        "paySubTypes": [
                            {
                                "payType": "DANA",
                                "payTypeName": "DANA",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 300,
                                "amountLimitEnd": 10000000
                            },
                            {
                                "payType": "ONLINEBANKING_BNI",
                                "payTypeName": "BNI",
                                "category": "ONLINEBANKING",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 10000
                            },
                            {
                                "payType": "ONLINEBANKING_BCA",
                                "payTypeName": "BCA",
                                "category": "ONLINEBANKING",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 10000,
                                "amountLimitEnd": 100000000
                            },
                            {
                                "payType": "QRIS",
                                "payTypeName": "QRIS",
                                "category": "BANKTRANSFER",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 300,
                                "amountLimitEnd": 20000000
                            },
                            {
                                "payType": "BANKTRANSFER_BSI",
                                "payTypeName": "BSI",
                                "category": "BANKTRANSFER",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 1,
                                "amountLimitEnd": 20000
                            },
                            {
                                "payType": "CIMBNIAGA",
                                "payTypeName": "CIMB Niaga VA",
                                "category": "BANKTRANSFER",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 10000,
                                "amountLimitEnd": 100000000
                            },
                            {
                                "payType": "BANKTRANSFER_MAYBANK",
                                "payTypeName": "Maybank",
                                "category": "BANKTRANSFER",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 10000
                            },
                            {
                                "payType": "BANKTRANSFER_BNI",
                                "payTypeName": "BNI",
                                "category": "BANKTRANSFER",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 10000,
                                "amountLimitEnd": 100000000
                            },
                            {
                                "payType": "BANKTRANSFER_PERMATA",
                                "payTypeName": "Permata",
                                "category": "BANKTRANSFER",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 10000,
                                "amountLimitEnd": 100000000
                            },
                            {
                                "payType": "ATMTRANSFER_ID",
                                "payTypeName": "ATM Bersama/Prima/Alto",
                                "category": "BANKTRANSFER",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 10000,
                                "amountLimitEnd": 100000000
                            },
                            {
                                "payType": "SHOPEEPAY_ID",
                                "payTypeName": "ShopeePay",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 1,
                                "amountLimitEnd": 10000000
                            },
                            {
                                "payType": "ALIPAY_CN",
                                "payTypeName": "AlipayCN",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 216.34
                            }
                        ]
                    }
                ],
                "cornerMarkText": "首充双倍-英语",
                "isRecommend": false
            },
            {
                "gameCode": "1703733676736",
                "commodityCode": "5201",
                "commodityName": "有償ダイヤ×2,200-英语",
                "commodityIcon": "https://xysy-cdn.gamenuts.com.tw/img/commodity/20241025/f2512dae-ac85-4bda-80b7-d5ef78b4cbb2.png",
                "commodityOriginalPrice": 249000,
                "commoditySellPrice": 249000,
                "commodityCurrency": "IDR",
                "commodityCurrencySymbol": "Rp",
                "currencyFrontLocation": "10",
                "preferentialExplain": "無償ダイヤ470 おまけ-英语",
                "payTypes": [
                    {
                        "payType": "ALIPAY_GLOBAL",
                        "payTypeName": "ALIPAY_GLOBAL",
                        "payLevel": 1,
                        "needValid": true,
                        "paySubTypes": [
                            {
                                "payType": "DANA",
                                "payTypeName": "DANA",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 300,
                                "amountLimitEnd": 10000000
                            },
                            {
                                "payType": "ONLINEBANKING_BNI",
                                "payTypeName": "BNI",
                                "category": "ONLINEBANKING",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 10000
                            },
                            {
                                "payType": "ONLINEBANKING_BCA",
                                "payTypeName": "BCA",
                                "category": "ONLINEBANKING",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 10000,
                                "amountLimitEnd": 100000000
                            },
                            {
                                "payType": "QRIS",
                                "payTypeName": "QRIS",
                                "category": "BANKTRANSFER",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 300,
                                "amountLimitEnd": 20000000
                            },
                            {
                                "payType": "BANKTRANSFER_BSI",
                                "payTypeName": "BSI",
                                "category": "BANKTRANSFER",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 10000,
                                "amountLimitEnd": 100000000
                            },
                            {
                                "payType": "CIMBNIAGA",
                                "payTypeName": "CIMB Niaga VA",
                                "category": "BANKTRANSFER",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 10000,
                                "amountLimitEnd": 100000000
                            },
                            {
                                "payType": "BANKTRANSFER_MAYBANK",
                                "payTypeName": "Maybank",
                                "category": "BANKTRANSFER",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 10000
                            },
                            {
                                "payType": "BANKTRANSFER_BNI",
                                "payTypeName": "BNI",
                                "category": "BANKTRANSFER",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 10000,
                                "amountLimitEnd": 100000000
                            },
                            {
                                "payType": "BANKTRANSFER_PERMATA",
                                "payTypeName": "Permata",
                                "category": "BANKTRANSFER",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 10000,
                                "amountLimitEnd": 100000000
                            },
                            {
                                "payType": "ATMTRANSFER_ID",
                                "payTypeName": "ATM Bersama/Prima/Alto",
                                "category": "BANKTRANSFER",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 10000,
                                "amountLimitEnd": 100000000
                            },
                            {
                                "payType": "SHOPEEPAY_ID",
                                "payTypeName": "ShopeePay",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 1,
                                "amountLimitEnd": 10000000
                            },
                            {
                                "payType": "ALIPAY_CN",
                                "payTypeName": "AlipayCN",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 216.34
                            }
                        ]
                    }
                ],
                "cornerMarkText": "首充双倍-英语",
                "isRecommend": false
            },
            {
                "gameCode": "1703733676736",
                "commodityCode": "5202",
                "commodityName": "有償ダイヤ×4,400-英语",
                "commodityIcon": "https://xysy-cdn.gamenuts.com.tw/img/commodity/20241025/5bcb0b95-df04-47d7-a207-d4afa6e21298.png",
                "commodityOriginalPrice": 499000,
                "commoditySellPrice": 499000,
                "commodityCurrency": "IDR",
                "commodityCurrencySymbol": "Rp",
                "currencyFrontLocation": "10",
                "preferentialExplain": "無償ダイヤ1000 おまけ-英语",
                "payTypes": [
                    {
                        "payType": "ALIPAY_GLOBAL",
                        "payTypeName": "ALIPAY_GLOBAL",
                        "payLevel": 1,
                        "needValid": true,
                        "paySubTypes": [
                            {
                                "payType": "DANA",
                                "payTypeName": "DANA",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 300,
                                "amountLimitEnd": 10000000
                            },
                            {
                                "payType": "ONLINEBANKING_BNI",
                                "payTypeName": "BNI",
                                "category": "ONLINEBANKING",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 10000
                            },
                            {
                                "payType": "ONLINEBANKING_BCA",
                                "payTypeName": "BCA",
                                "category": "ONLINEBANKING",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 10000,
                                "amountLimitEnd": 100000000
                            },
                            {
                                "payType": "QRIS",
                                "payTypeName": "QRIS",
                                "category": "BANKTRANSFER",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 300,
                                "amountLimitEnd": 20000000
                            },
                            {
                                "payType": "BANKTRANSFER_BSI",
                                "payTypeName": "BSI",
                                "category": "BANKTRANSFER",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 10000,
                                "amountLimitEnd": 100000000
                            },
                            {
                                "payType": "CIMBNIAGA",
                                "payTypeName": "CIMB Niaga VA",
                                "category": "BANKTRANSFER",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 10000,
                                "amountLimitEnd": 100000000
                            },
                            {
                                "payType": "BANKTRANSFER_MAYBANK",
                                "payTypeName": "Maybank",
                                "category": "BANKTRANSFER",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 10000
                            },
                            {
                                "payType": "BANKTRANSFER_BNI",
                                "payTypeName": "BNI",
                                "category": "BANKTRANSFER",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 10000,
                                "amountLimitEnd": 100000000
                            },
                            {
                                "payType": "BANKTRANSFER_PERMATA",
                                "payTypeName": "Permata",
                                "category": "BANKTRANSFER",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 10000,
                                "amountLimitEnd": 100000000
                            },
                            {
                                "payType": "ATMTRANSFER_ID",
                                "payTypeName": "ATM Bersama/Prima/Alto",
                                "category": "BANKTRANSFER",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 10000,
                                "amountLimitEnd": 100000000
                            },
                            {
                                "payType": "SHOPEEPAY_ID",
                                "payTypeName": "ShopeePay",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 1,
                                "amountLimitEnd": 10000000
                            },
                            {
                                "payType": "ALIPAY_CN",
                                "payTypeName": "AlipayCN",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 216.34
                            }
                        ]
                    }
                ],
                "cornerMarkText": "首充双倍-英语",
                "isRecommend": false
            },
            {
                "gameCode": "1703733676736",
                "commodityCode": "5203",
                "commodityName": "有償ダイヤ×7,400-英语",
                "commodityIcon": "https://xysy-cdn.gamenuts.com.tw/img/commodity/20241025/ad05b36a-f3ed-4cd3-8d8d-b0f498f6aae5.png",
                "commodityOriginalPrice": 799000,
                "commoditySellPrice": 799000,
                "commodityCurrency": "IDR",
                "commodityCurrencySymbol": "Rp",
                "currencyFrontLocation": "10",
                "preferentialExplain": "無償ダイヤ2100 おまけ-英语",
                "payTypes": [
                    {
                        "payType": "ALIPAY_GLOBAL",
                        "payTypeName": "ALIPAY_GLOBAL",
                        "payLevel": 1,
                        "needValid": true,
                        "paySubTypes": [
                            {
                                "payType": "DANA",
                                "payTypeName": "DANA",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 300,
                                "amountLimitEnd": 10000000
                            },
                            {
                                "payType": "ONLINEBANKING_BNI",
                                "payTypeName": "BNI",
                                "category": "ONLINEBANKING",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 10000
                            },
                            {
                                "payType": "ONLINEBANKING_BCA",
                                "payTypeName": "BCA",
                                "category": "ONLINEBANKING",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 10000,
                                "amountLimitEnd": 100000000
                            },
                            {
                                "payType": "QRIS",
                                "payTypeName": "QRIS",
                                "category": "BANKTRANSFER",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 300,
                                "amountLimitEnd": 20000000
                            },
                            {
                                "payType": "BANKTRANSFER_BSI",
                                "payTypeName": "BSI",
                                "category": "BANKTRANSFER",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 10000,
                                "amountLimitEnd": 100000000
                            },
                            {
                                "payType": "CIMBNIAGA",
                                "payTypeName": "CIMB Niaga VA",
                                "category": "BANKTRANSFER",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 10000,
                                "amountLimitEnd": 100000000
                            },
                            {
                                "payType": "BANKTRANSFER_MAYBANK",
                                "payTypeName": "Maybank",
                                "category": "BANKTRANSFER",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 10000
                            },
                            {
                                "payType": "BANKTRANSFER_BNI",
                                "payTypeName": "BNI",
                                "category": "BANKTRANSFER",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 10000,
                                "amountLimitEnd": 100000000
                            },
                            {
                                "payType": "BANKTRANSFER_PERMATA",
                                "payTypeName": "Permata",
                                "category": "BANKTRANSFER",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 10000,
                                "amountLimitEnd": 100000000
                            },
                            {
                                "payType": "ATMTRANSFER_ID",
                                "payTypeName": "ATM Bersama/Prima/Alto",
                                "category": "BANKTRANSFER",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 10000,
                                "amountLimitEnd": 100000000
                            },
                            {
                                "payType": "SHOPEEPAY_ID",
                                "payTypeName": "ShopeePay",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 1,
                                "amountLimitEnd": 10000000
                            },
                            {
                                "payType": "ALIPAY_CN",
                                "payTypeName": "AlipayCN",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 216.34
                            }
                        ]
                    }
                ],
                "cornerMarkText": "首充双倍-英语",
                "isRecommend": false
            },
            {
                "gameCode": "1703733676736",
                "commodityCode": "5204",
                "commodityName": "有償ダイヤ×14,800-英语",
                "commodityIcon": "https://xysy-cdn.gamenuts.com.tw/img/commodity/20241025/e9386db1-b45d-4d9e-ab81-a819f98941c6.png",
                "commodityOriginalPrice": 1699000,
                "commoditySellPrice": 1699000,
                "commodityCurrency": "IDR",
                "commodityCurrencySymbol": "Rp",
                "currencyFrontLocation": "10",
                "preferentialExplain": "無償ダイヤ5080 おまけ-英语",
                "payTypes": [
                    {
                        "payType": "ALIPAY_GLOBAL",
                        "payTypeName": "ALIPAY_GLOBAL",
                        "payLevel": 1,
                        "needValid": true,
                        "paySubTypes": [
                            {
                                "payType": "DANA",
                                "payTypeName": "DANA",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 300,
                                "amountLimitEnd": 10000000
                            },
                            {
                                "payType": "ONLINEBANKING_BNI",
                                "payTypeName": "BNI",
                                "category": "ONLINEBANKING",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 10000
                            },
                            {
                                "payType": "ONLINEBANKING_BCA",
                                "payTypeName": "BCA",
                                "category": "ONLINEBANKING",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 10000,
                                "amountLimitEnd": 100000000
                            },
                            {
                                "payType": "QRIS",
                                "payTypeName": "QRIS",
                                "category": "BANKTRANSFER",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 300,
                                "amountLimitEnd": 20000000
                            },
                            {
                                "payType": "BANKTRANSFER_BSI",
                                "payTypeName": "BSI",
                                "category": "BANKTRANSFER",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 10000,
                                "amountLimitEnd": 100000000
                            },
                            {
                                "payType": "CIMBNIAGA",
                                "payTypeName": "CIMB Niaga VA",
                                "category": "BANKTRANSFER",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 10000,
                                "amountLimitEnd": 100000000
                            },
                            {
                                "payType": "BANKTRANSFER_MAYBANK",
                                "payTypeName": "Maybank",
                                "category": "BANKTRANSFER",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 10000
                            },
                            {
                                "payType": "BANKTRANSFER_BNI",
                                "payTypeName": "BNI",
                                "category": "BANKTRANSFER",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 10000,
                                "amountLimitEnd": 100000000
                            },
                            {
                                "payType": "BANKTRANSFER_PERMATA",
                                "payTypeName": "Permata",
                                "category": "BANKTRANSFER",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 10000,
                                "amountLimitEnd": 100000000
                            },
                            {
                                "payType": "ATMTRANSFER_ID",
                                "payTypeName": "ATM Bersama/Prima/Alto",
                                "category": "BANKTRANSFER",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 10000,
                                "amountLimitEnd": 100000000
                            },
                            {
                                "payType": "SHOPEEPAY_ID",
                                "payTypeName": "ShopeePay",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 1,
                                "amountLimitEnd": 10000000
                            },
                            {
                                "payType": "ALIPAY_CN",
                                "payTypeName": "AlipayCN",
                                "payLevel": 2,
                                "needValid": true,
                                "amountLimitStart": 216.34
                            }
                        ]
                    }
                ],
                "cornerMarkText": "首充双倍-英语",
                "isRecommend": false
            }
        ]
    }
)


Mock.mock(
    RegExp('.*/app/order/createOrderThenPay.*'),
    {
        "code": 0,
        "data": {
            "orderCode": "112312121",
            "payPage": "https://www.baidu.com",
            "waitSecond": 100
        },
        "message": "错误信息"
    }
)

Mock.mock(
    RegExp('.*/app/order/getPayResult.*'),
    {"code":0,"message":"การดำเนินการสำเร็จ","data":{"timeRemain":464}}
)

