import instance from "@/utils/request"

export const apiGameInit = () => {
    return instance.get('/app/game/init', {
        customConfig: {
            disableAuth: true
        }
    })
}

export const apiGameReginLang = () => {
    return instance.get('/app/game/listReginAndLanguage', {
        customConfig: {
            disableAuth: true
        }
    })
}

export const apiMaintainInfo = () => {
    return instance.get('/app/game/getMaintainInfo')
}

export const apiPosition = function (position) {
    return instance.get('/app/resource/getByPosition', {
        params: {
            position
        },
        customConfig: {
            disableAuth: true
        }
    })
}

// (暂时没用)
export const apiPositionList = function (params) {
    return instance.get('/app/resource/listByPositions', {
        params,
        customConfig: {
            disableAuth: true
        }
    })
}