import { isMobile } from '@/utils/utils'
import { getGameInfoByUrl, getGameTypeByUrl } from '@/utils/game'
import { logClickAiHelp } from '@/utils/log'

const icon_id = 'my_ai_helper_icon_id'

function getAiInfo() {
  const appIds = {
    'xy': 'cyouforeign_platform_abf3849500d23c4d3f689f02726abe30',
    'ps_jp': 'cyouforeign_platform_19f672af3f4deada30c32c1fd3135fa2',
    'ps_kr': 'cyouforeign_platform_73572b0b6200bb2ef908a0afb0b905c0'
  }
  // const gameInfo = getGameInfoByUrl()
  const gameInfo = { name: getGameTypeByUrl(), regionName: 'jp' }
  let appId

  if (gameInfo.name === 'xy') {
    appId = appIds[gameInfo.name]
  } else if (gameInfo.name === 'ps') {
    appId = appIds[gameInfo.regionName === 'jp' ? `ps_jp` : `ps_kr`]
  }
  return { domain: 'cyouforeign.aihelp.net', appId }
}

function loadScriptAsync(uri) {
  return new Promise((resolve, reject) => {
    if (!document.getElementById('my_ai_helper')) {
      const tag = document.createElement('script')
      tag.src = uri
      tag.id = 'my_ai_helper'
      tag.async = true
      tag.onload = () => {
        resolve()
      }
      tag.onerror = (err) => {
        reject(err)
      }
      const head = document.head || document.getElementsByTagName('head')[0]
      const firstScriptTag = head.getElementsByTagName('script')[0]
      if (firstScriptTag) {
        head.insertBefore(tag, firstScriptTag)
      } else {
        head.appendChild(tag)
      }
    }
  })
}

function loadAiHelpCssFile(url) {
  const cssLink = document.createElement('link')
  cssLink.rel = 'stylesheet'
  cssLink.type = 'text/css'
  cssLink.href = url
  const head = document.head || document.getElementsByTagName('head')[0]
  head.appendChild(cssLink)
}

function createAiHelperStyle(styleContent) {
  const style = document.createElement('style')
  style.type = 'text/css'
  style.appendChild(document.createTextNode(styleContent))
  const head = document.head || document.getElementsByTagName('head')[0]
  head.appendChild(style)
}


function handleShowBox() {
  logClickAiHelp()
  window.AIHelpSupport.show('E002')
}

function createIcon(containerId, style = {}) {
  const span = document.createElement('span')
  span.id = icon_id

  const iconStyle = {
    display: 'block',
    width: '40px',
    height: '33px',
    'background-repeat': 'no-repeat',
    'background-image': 'url(/static/img/aihelp.png)',
    'background-size': 'cover',
    cursor: 'pointer',
    'z-index': 999
  }
  const position = isMobile() ? {
    position: 'fixed',
    right:0,
    top: 'calc(50vh + 100px)'
  } : {
    position: 'fixed',
    bottom: '190px',
    left: 'calc(50vw + 600px)'
  }
  const nstyle = Object.assign(iconStyle, position, style)
  Object.keys(nstyle).forEach(k => {
    span.style.setProperty(k, nstyle[k])
  })
  span.addEventListener('click', handleShowBox)

  if (containerId) {
    const timerID = setInterval(() => {
      const container = document.getElementById(containerId)
      if (container) {
        container.style.position = 'relative'
        container.appendChild(span)
        clearInterval(timerID)
      }
    }, 1 * 1000)
  } else {
    document.body.appendChild(span)
  }
}

export default function aihelper(config, callback) {
  // let initConfig = {
  //   domain: "THIS IS YOUR APP DOMAIN",
  //   appId: "THIS IS YOUR APP ID",
  //   language: "THIS IS YOUR DEFAULT LANGUAGE(OPTIONAL)"
  // };
  const initInfo = getAiInfo()

  loadScriptAsync('https://cdn.aihelp.net/webchatv5/aihelp.js').then(() => {
    if (window.AIHelpSupport) {
      window.AIHelpSupport.initialize(initInfo)
      window.AIHelpSupport.registerAsyncEventListener('initialization', function(jsonData) {
        // When init job is done, you can get callback here
        if (jsonData.isSuccess) {
          if (callback && typeof callback === 'function') {
            callback(window.AIHelpSupport)
          }
        } else {
          console.error(jsonData.message)
        }
      })
    }
  }).catch(err => {
    console.error(err)
  })
  // 样式 style
  // createAiHelperStyle(config.aiStyle)
  loadAiHelpCssFile(config.aiStyle)

  window.addEventListener('DOMContentLoaded', () => {
    // 创建图标
    createIcon(config.containerId, config.iconStyle)
  })

  return function destroy() {
    const icon = document.querySelector(`#${icon_id}`)
    if (icon) {
      icon.removeEventListener('click', handleShowBox)
      icon.remove()
    }
    window.AIHelpSupport = null
  }

}
