/**
 * 谷歌日志埋点，两个：
 *      - 西游东南亚
 *      - 排少日本
 */


export const logEvent = (name, val) => {
    console.log(`logName：${name}${val ? '，logVal：' + JSON.stringify(val) : ''}`)
    window.gtag('event', name, val);   // google事件统计
}

/**************** 登录相关（4个） ******************/
// 通用
export const logSignUp = ({placement, method}) => logEvent('sign_up', {placement, method})

export const logLogin = (role_id) => logEvent('login', { role_id })

export const logLoginChange = (placement) => logEvent('login_change', { placement })  

export const logLoginFail = (error_type) => logEvent('login_fail', { error_type })


/**************** 说明、菜单相关（13 + 6个） ******************/
// 通用
export const logCustomerCenter = () => logEvent('customer_center')

export const logViewMenu = () => logEvent('view_menu')

export const logClickInmenu = (menu_name) => logEvent('click_inmenu', { menu_name })

export const logClickLogout = () => logEvent('click_logout')

export const logClickRecord = () => logEvent('click_record')

export const logClickBanner = (banner_id) => logEvent('click_banner', { banner_id })    // 序号 1 开始

export const logChangeLanguage = () => logEvent('change_language')

export const logDefaultGl = (region) => logEvent('default_gl', { region })

export const logDefaultLn = (language) => logEvent('default_ln', { language })

export const logChangeGl = (region) => logEvent('change_gl', { region })

export const logChangeLn = (language) => logEvent('change_ln', { language })

export const logClickAiHelp = () => logEvent('click_aihelp')

export const logVipCenter = () => logEvent('vip_center')

// 西游东南亚
export const logPolicy = () => logEvent('policy')  
export const logRule = () => logEvent('rule')
export const logFunsClub = () => logEvent('funs_club')

// 排少日本
export const logPolicy1 = () => logEvent('policy1')       
export const logPolicy2 = () => logEvent('policy2')       
export const logPolicy3 = () => logEvent('policy3')   


/**************** 购买相关（8 + 8个） ******************/
export const logSelectItem = ({ item_id, price, currency }) => logEvent('select_item', { item_id, price, currency })        // 没有这个场景了，和下面一个重复

export const logBeginCheckout = ({ item_id, price, currency }) => logEvent('begin_checkout', { item_id, price, currency })

export const logAddNumber = (item_id) => logEvent('add_number', { item_id })

export const logReduceNumber = (item_id) => logEvent('reduce_number', { item_id })

export const logChooseMethod = (method) => logEvent('choose_method', { method })

export const logPurchaseClick = ({ item_id, revenue, price, quantity, currency, coupon, method }) => logEvent('purchase_click', { item_id, revenue, price, quantity, currency, coupon, method })

export const logPurchaseSuccess = ({ item_id, revenue, price, quantity, currency, coupon, method }) => logEvent('purchase_success', { item_id, revenue, price, quantity, currency, coupon, method })

export const logPurchaseFail = (error_type) => logEvent('purchase_fail', { error_type })

// 西游东南亚（暂无优惠券）
export const logClickCoupon = (item_id) => logEvent('click_coupon', { item_id })

// 排少日本（暂无优惠券）
export const logClickCouponPs = (coupon) => logEvent('click_coupon', { coupon })   
export const logCancelCoupon = (coupon) => logEvent('cancel_coupon', { coupon }) 
export const logUserdCoupon = ( ) => logEvent('used_coupon')       
export const logExpiredCoupon = ( ) => logEvent('expired_coupon')  


/**************** 交易记录相关（2个） ******************/
// 通用
export const logChangeDate = () => logEvent('change_date')

export const logSelectDate = () => logEvent('select_date')


/**************** 收藏/添加桌面相关（6个） ******************/
export const logClickSave = (placement) => logEvent('click_save', { placement })

export const logPopupDisplay = (popup_type) => logEvent('popup_display', { popup_type })

export const logPopupClick = ({popup_type, click_result}) => logEvent('popup_click', { popup_type, click_result })
