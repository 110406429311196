const state = {
    
}

const mutations = {
    
}

const actions = {
    log(name, params = {}, config = {}) {
        
    }
}

export default {
    namespaced: true,    // 命名空间，设置被 mapXxx 方法识别到
    state,
    mutations,
    actions
}