import { apiOrderCancel, apiOrderList } from '@/api/mall';
import i18n from '@/i18n';
import { comfirmBox, formatCurrencyToThousand, formatTimeZoneDateTime, isMobile } from '@/utils';
import { getPayStatusByCode } from '@/utils/pay';

const currentDate = new Date()
currentDate.setMonth(new Date().getMonth() - 6)
const state = {
  orderList: [],
  loading: true,
  dateRange: {
    min: new Date(new Date().setMonth(new Date().getMonth() - 6)),
    max: new Date()
  },
  orderSearch: {
    startTime: currentDate,
    endTime: new Date()
  },
  orderPage: {
    current: 1,
    size: 10,
    total: 0
  }
}

const mutations = {
  SET_ORDER_LIST(state, arr) {
    state.orderList = arr
  },
  SET_LOADING(state, val) {
    state.loading = val
  },
  SET_ORDER_SEARCH(state, info) {
    state.orderSearch = info
  },
  SET_ORDER_PAGE(state, info) {
    state.orderPage = info
  }
}

const actions = {
  async getOrderList({ state, commit, rootGetters }) {
    const { current, size } = state.orderPage
    const { startTime, endTime } = state.orderSearch

    isMobile() && commit('SET_ORDER_LIST', []);
    commit('SET_LOADING', true)

    try {
      const result = await apiOrderList({
        pageNum: current,
        pageSize: size,
        startTime: startTime?.getTime(),
        endTime: endTime?.getTime()
      })

      commit('SET_LOADING', false)
      if (result.code === 0) {
        const { total, records } = result.data
        commit('SET_ORDER_PAGE', { ...state.orderPage, total })
        commit('SET_ORDER_LIST', records.map(record => {
          return {
            ...record,
            createTime: formatTimeZoneDateTime(rootGetters.timeZone, record.createTime),
            status: getPayStatusByCode(record.orderStatus),
            orderPrizeShow: record.currencyFrontLocation == 11
              ? formatCurrencyToThousand(record.orderNeedPayPrice) + ' ' + record.currency
              : record.currency + ' ' + formatCurrencyToThousand(record.orderNeedPayPrice)
          }
        }))
      }
    }catch(e) {
      commit('SET_LOADING', false)
    }
  },
  async cancelOrder({ dispatch, commit, rootGetters, state }, orderCode) {
    comfirmBox(i18n.t('sys.warn.orderCancel'),
      async () => {
        commit('SET_LOADING', true)
        const result = await apiOrderCancel(orderCode)
        if (result.code === 0) {
          if(rootGetters.isMobile) {
            commit('SET_ORDER_PAGE', {
              ...state.orderPage,
              current: 1
            })
          }
          dispatch('getOrderList')
        }else {
          commit('SET_LOADING', false)
        }
      })

  }
}

export default {
  namespaced: true,    // 命名空间，设置被 mapXxx 方法识别到
  state,
  mutations,
  actions
}

