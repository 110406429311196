import i18n, { changeLang } from '@/i18n'
import { apiGameInit, apiGameReginLang, apiPosition, apiMaintainInfo } from '@/api/game'
import { splitTimeZone } from '@/utils'
import { setLang, setCountry, getLang, getCountry } from '@/utils/storage'
import { getNavLang } from '@/utils'
import { getGameInfoByUrl, getGameTitleByUrl } from '@/utils/game'
import { logDefaultGl, logDefaultLn, logChangeGl, logChangeLn } from '@/utils/log'

const state = {
    device: 'pc',
    country: getCountry(),    // 国家
    lang: getLang(),  // 语言
    countryLang: [],
    gameInfo: {},  // 初始化信息
    stepName: ''   // login、role、mall
}

const mutations = {
    SET_DEVICE(state, data) {
        state.device = data
    },
    SET_COUNTRY(state, val) {
        state.country = val
    },
    SET_LANG(state, data) {
        state.lang = data
    },
    SET_COUNTRY_LANG(state, data) {
        state.countryLang = data
    },
    SET_GAME_INFO(state, val) {
        state.gameInfo = val
    },
    SET_STEP_NAME(state, val) {
        state.stepName = val
    }
}

const actions = {
    async gameRegionLang({ state, commit, dispatch }) {
        const result = await apiGameReginLang()
        if(result.code === 0) {
            const { regionCodeDefault, regionCodePosition, regions } = result.data

            const list = regions.map(({ 
                regionNameCommon,
                languageCodeDefault, 
                regionCode, 
                languages 
            }) => {
                languageCodeDefault = languageCodeDefault?.replace('_', '-')

                return {
                    country: regionNameCommon,
                    value: regionCode,
                    defaultLang: languageCodeDefault,
                    langs: languages.map(({ languageNameLocal, languageCode }) => {
                        languageCode = languageCode?.replace('_', '-')

                        return {
                            label: languageNameLocal,
                            value: languageCode,
                            key: regionCode
                        }
                    })
                }
            })

            commit('SET_COUNTRY_LANG', list)

            const isCountry = val => list.find(item => item.value === val)?.value   // 是否有该国家
            const country = isCountry(state.country) || isCountry(regionCodePosition) || isCountry(regionCodeDefault) || isCountry(list[0]?.value)

            country && dispatch('chooseCountry', {country, isDefault: true})

        }
    },
    async gameInit({ commit }) {
        const result = await apiGameInit()
        if(result.code === 0) {
            const info = getGameInfoByUrl()
            const { timeZoneName, timeZoneGMT } = splitTimeZone(result.data.timeZone)
            commit('SET_GAME_INFO', {
                ...result.data,
                ...info,
                timeZoneName,
                timeZoneGMT
            })
        }
    },
    chooseCountry({ state, commit, dispatch }, {country, isDefault}) {
        setCountry(country)
        commit('SET_COUNTRY', country)

        const { countryLang, lang } = state
        const countryInfo = countryLang.find(item => item.value === country)
        const { langs, defaultLang } = countryInfo
        const isLang = val => langs?.find(item => item.value === val)?.value    // 是否有该语言
        const langVal = isLang(lang) || isLang(getNavLang()) || isLang(defaultLang) || isLang[langs[0].value]

        langVal && dispatch('chooseLang', { lang: langVal, isDefault })


        isDefault ? logDefaultGl(country) : logChangeGl(country)
    },
    chooseLang({ commit }, {lang, isDefault}) {
        i18n.locale = lang
        commit('SET_LANG', lang)
        changeLang(lang)
        setLang(lang)
        
        document.title = i18n.t(getGameTitleByUrl() || 'sys.header.title')
        document.documentElement.lang = lang

        isDefault ? logDefaultLn(lang) : logChangeLn(lang)
    },
    getPosition({ state }, position) {
        return new Promise((resolve) => {
            apiPosition(position).then(result => {
                if(result?.code === 0) {
                    resolve(result.data?.resources || [])
                }else {
                    resolve([])
                }
            })
            
        })
    },
    getMaintainInfo({commit, state}) {
        return new Promise(resolve => {
            apiMaintainInfo().then(result => {
                if(result.code === 0) {
                    const maintainInfo = result.data
                    const { timeZoneName, timeZoneGMT } = splitTimeZone(maintainInfo.timeZone)
                    if(maintainInfo.maintaining) {
                        commit('SET_GAME_INFO', {
                            ...state.gameInfo,
                            ...maintainInfo,
                            timeZoneName,
                            timeZoneGMT
                        })
                    }
                    resolve(maintainInfo.maintaining)
                }
            })
        })
    }
}

export default {
    namespaced: true,    // 命名空间，设置被 mapXxx 方法识别到
    state,
    mutations,
    actions
}