import Mock from 'mockjs'

Mock.mock(
    RegExp('.*/app/game/init.*'),
    {
        "code": 0,
        "message": "操作成功",
        "data": {
            "gameCode": "1675759979322",
            "gameName": "西游-港澳台新-dev",
            "gameIcon": "https://ecmb.bdimg.com/tam-ogel/1856315814_-1643072931_88_88.png",
            "timeZone": "Asia/Taipei(+08:00)",
            "maintaining": false,
            "maintainStartTime": 1722211200000,
            "maintainEndTime": 1722227400000,
            "regionLanguage": null
        }
    }
)


Mock.mock(
    RegExp('.*/app/game/listReginAndLanguage.*'),
    {
        "code": 0,
        "message": "操作成功",
        "data": {
            "regions": [
                {
                    "regionNameCommon": "Japan",
                    "regionCode": "JP",
                    "languages": [
                        {
                            "languageCode": "ja-JP",
                            "languageNameLocal": "日本語"
                        },
                        {
                            "languageCode": "zh-CN",
                            "languageNameLocal": "简体中文"
                        },
                        {
                            "languageCode": "zh-TW",
                            "languageNameLocal": "繁體中文"
                        }
                    ],
                    "languageCodeDefault": "ja-JP"
                },
                {
                    "regionNameCommon": "Indonesia",
                    "regionCode": "ID",
                    "languages": [
                        {
                            "languageCode": "en-US",
                            "languageNameLocal": "english"
                        },
                        {
                            "languageCode": "id-ID",
                            "languageNameLocal": "Bahasa Indonesia"
                        }
                    ],
                    "languageCodeDefault": "en-US"
                }
            ],
            "regionCodeDefault": "JP"
        }
    }
)

Mock.mock(
    RegExp('.*/app/resource/getByPosition.*'),
    {
        "code": 0,
        "message": "操作成功",
        "data": {
            "resourcePosition": 2,
            "resourceType": "IMAGE",
            "resources": [
                {
                    "value": "https://xysy-cdn.gamenuts.com.tw/img/resource/20240919/344d57ff-9082-4166-9c12-97954692cf3f.png",
                    "link": "https://www.baidu.com/"
                },
                {
                    "value": "https://xysy-cdn.gamenuts.com.tw/img/resource/20240919/344d57ff-9082-4166-9c12-97954692cf3f.png",
                    "link": "https://www.youdao.com/"
                },
                {
                    "value": "https://xysy-cdn.gamenuts.com.tw/img/resource/20240919/344d57ff-9082-4166-9c12-97954692cf3f.png"
                }
            ]
        }
    }
)


Mock.mock(
    RegExp('.*/app/game/getMaintainInfo.*'),
    {
        "code": 0,
        "message": "操作成功",
        "data": {
            "timeZone": "Asia/Taipei(+8:00)",
            "gameCode": "1675759979322",
            "maintaining": false,
            "maintainStartTime": 1720369135000,
            "maintainEndTime": 1720434314000
        }
    }
)