import { getSource } from "@/utils/storage"

const getters = {
  isPc: state => state.game.device === 'pc',
  isMobile: state => state.game.device === 'm',
  country: state => state.game.country,
  lang: state => state.game.lang,
  isMaintain: state => state.game.gameInfo.maintaining,
  isInitFinish: state => state.user.isInitFinish,
  isInGame: state => (state.user.userInfo.userSource || getSource()) === 'ingame',

  isLogin: state => Object.keys(state.user.userInfo).length > 0,
  nickname: state => state.user.userInfo?.userName || state.user.userInfo?.userCode,
  isRole: state => !!state.user.userInfo.gameRoleName,

  isSelectGoods: state => Object.keys(state.mall.goodsInfo).length > 0,
  isCanPay: state => state.mall.payList.length > 0,
  payRecentlyUse: state => state.user.userInfo.payRecentlyUse
}
export default getters