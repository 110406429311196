<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import { isMobile } from '@/utils/utils';
import { throttle } from 'lodash-es';
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'App',
  created() {
    this.resizeChange = throttle(this.resizeChange, 200)
    this.resizeChange()
    window.addEventListener('resize', this.resizeChange, false)
  },
  methods: {
    ...mapState('game', ['device']),
    ...mapMutations('game', [ 'SET_DEVICE' ]),
    ...mapMutations('user', [ 'SET_CLIENT_WIDTH' ]),
    resizeChange() {
      this.SET_DEVICE(isMobile() ? 'm' : 'pc');
      this.SET_CLIENT_WIDTH(document.documentElement.clientWidth)
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeChange, false)
  }
}
</script>

<style lang="scss">
@import '@/style/index';
</style>
